.cardim {
    width: 18rem;
    height: 30rem;
    box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.5);
    display: inline-block;
    margin: 3rem;
    z-index: 5;
}

.inCard1 {
    width: 18rem;
    height: 14.2rem;
    background-color: white;
    line-height: 2.5rem;
    padding-top: 1rem;

}

.inCard2 {
    width: 18rem;
    height: 3rem;
    line-height: 3rem;
    background-color: rgb(128, 14, 14);
    color: white;
    cursor: pointer;
}

.inCard2:hover {
    color: #c00000;
    background-color: #eb9e9e;
    font-weight: 400;
    cursor: pointer;
}

.imagg {
    width: 18rem;
    height: 12rem;
    object-fit: cover;
    object-position: bottom left;
    /* background-size: cover;
    background-position: bottom left; */
    transition: 1s;
    z-index: 10;
    /* background-color: white; */

}

.cardim:hover .imagg {
    object-fit: cover;
    object-position: top right;
    /* background-size: cover;
    background-position: top right; */
}

.prudu2 {
    width: 20%;
    /* position: absolute; */
    /* top: 0%; */
    /* left: 0%;
    bottom: 1%;
    padding: 4%; */

}

.proPic2 {
    /* position: absolute;
    left: 0%; */
    width: 20%;
    height: 11.6vh;
    float: left;
    /* bottom: 0.9%; */
}

.product {
    position: absolute;
    left: 0;
    bottom: 1%;
    width: 25%;
    height: 12vh;
    position: fixed;
    background-color: white;
    z-index: 9999;
    text-align: right;    
    border: rgb(128, 14, 14) 2px solid;

}

.prudu {
    width: 75%;
    position: absolute;
    left: 0%;
    bottom: 1%;
    padding: 4%;

}

.proPic {
    position: absolute;
    left: 0%;
    width: 25%;
    height: 11.5vh;
    bottom: 0%;
}

.added {
    background-color: rgb(128, 14, 14);
    color: white;
}

.added:hover {
    background-color: #eb9e9e;
    color: #c00000;
    cursor: pointer;
    padding-top: 10%;
    font-weight: 400;
}

.spanIn {
    margin: 12%;
}

.spanIn:hover {
    color: white;
}
